import React from 'react';
import './MyPage.css';

// 仮のユーザーデータ
const userData = {
  name: 'うに',
  bio: '最近ハマり始めた新参者です',
  reviewCount: 12,
  joinCount2024: 30,
  success2024:20,
  failure2024:10
};

// 仮のユーザーのレビュー投稿リスト
const userReviews = [
  { id: 1, title: '不思議な森の謎解き冒険', rating: 4.5, summary: '家族で楽しめる素晴らしい体験でした！' },
  { id: 2, title: '怪盗からの脱出', rating: 4.0, summary: '難易度が高く、大人数での挑戦がおすすめです。' },
  { id: 3, title: '幽霊屋敷からの脱出', rating: 5.0, summary: 'とても怖かったけれど、スリルがあって面白かったです！' }
];

function MyPage() {
  return (
    <div className="mypage">
      <h1>マイページ</h1>
      <section className="profile">
        <h2>プロフィール</h2>
        <p>名前: {userData.name}</p>
        <p>自己紹介: {userData.bio}</p>
        <p>レビュー投稿数: {userData.reviewCount}</p>
        <p>今年の謎解き参加公演数: {userData.joinCount2024}</p>
        <p>今年の謎解き成功数: {userData.success2024}</p>
        <p>今年の謎解き失敗数: {userData.failure2024}</p>
        <p>今年の謎解き成功率: {userData.failure2024/userData.joinCount2024*100}%</p>
      </section>
      <section className="reviews">
        <h2>投稿したレビュー</h2>
        <ul>
          {userReviews.map(review => (
            <li key={review.id}>
              <h3>{review.title}</h3>
              <p>評価: {review.rating} / 5.0</p>
              <p>{review.summary}</p>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default MyPage;
