import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';  // Cognito設定情報を保持するファイル
import Navbar from './components/Navber/Navbar';
import HomePage from './components/HomePage/HomePage';
import MyPage from './components/MyPage/MyPage';
import ReviewList from './components/ReviewList/ReviewList';
import ReviewDetail from './components/ReviewDetail/ReviewDetail';
import CategoryPage from './components/CategoryPage/CategoryPage';
import CategoryList from './components/CategoryList/CategoryList';
import EventDetail from './components/EventDetail/EventDetail';
import EventList from './components/EventList/EventList';
import AddEvent from './components/AddEvent/AddEvent';
import ContactPage from './components/ContactPage/ContactPage';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';
import Login from './components/Login/Login';  // ログアウトコンポーネントをインポート
import './App.css';

Amplify.configure(awsExports);  // Amplifyの設定を反映

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar />
        <div className="main">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/events" element={<EventList />} />
            <Route path="/addevent" element={<AddEvent />} />
            <Route path="/event/:id" element={<EventDetail />} />
            <Route path="/reviews" element={<ReviewList />} />
            <Route path="/review/:id" element={<ReviewDetail />} />
            <Route path="/categories" element={<CategoryList />} />
            <Route path="/category/:categoryName" element={<CategoryPage />} />
            <Route path="/mypage" element={
                <MyPage />
            } />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/login" element={<Login />} /> {/* ログアウトルートを追加 */}
            <Route path="*" element={<div>404 Not Found</div>} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;  // 認証機能を削除
