import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ReviewDetail.css';


// 仮の詳細データ（実際にはAPIから取得することが一般的です）
const reviewsData = {
  1: { id: 1, title: '不思議な森の謎解き冒険', rating: 4.5, summary: '家族で楽しめる素晴らしい体験でした！' },
  2: { id: 2, title: '怪盗からの脱出', rating: 4.0, summary: '難易度が高く、大人数での挑戦がおすすめです。' },
  3: { id: 3, title: '幽霊屋敷からの脱出', rating: 5.0, summary: 'とても怖かったけれど、スリルがあって面白かったです！' }

};

function ReviewDetail() {
  const { id } = useParams();
  const [reviewDetail, setReviewDetail] = useState(null);

  useEffect(() => {
    // パラメータから得たIDに基づいて詳細データを取得
    setReviewDetail(reviewsData[id]);
  }, [id]);

  if (!reviewDetail) {
    return <div>Loading...</div>;
  }

  return (
    <div className="reviewDetail">
      <h1>{reviewDetail.title}</h1>
      <p>Rating: {reviewDetail.rating} / 5.0</p>
      <p>{reviewDetail.summary}</p>
      <p>{reviewDetail.review}</p>
    </div>
  );
}

export default ReviewDetail;
