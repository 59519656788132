import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';  
import '@aws-amplify/ui-react/styles.css';  
import './Navbar.css';
import { signInWithRedirect } from '@aws-amplify/auth';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { user, signOut, route, toSignIn } = useAuthenticator((context) => [context.user, context.route]);

  return (
    <nav>
      <div className="logo">
        <Link to="/">謎解きログ</Link>
      </div>
      <div className="hamburger-menu" onClick={() => setIsOpen(!isOpen)}>
        <span>メニュー</span>
      </div>
      <ul className={`nav-links ${isOpen ? "show" : ""}`}>
        <li><Link to="/events">公演一覧</Link></li>
        <li><Link to="/reviews">最新のレビュー</Link></li>
        <li><Link to="/categories">謎作成団体一覧</Link></li>
        <li><Link to="/contact">お問い合わせ</Link></li>
        <li><Link to="/mypage">マイページ</Link></li>
        <li>
          {user ? (
            <Link to="#" onClick={signOut} className="nav-link">
              ログアウト
            </Link>
          ) : (
            <Link to="/login" onClick={toSignIn} className="nav-link">
              ログイン
            </Link>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default function NavbarWrapper() {
  return (
    <Authenticator.Provider>
      <Navbar />
    </Authenticator.Provider>
  );
}
