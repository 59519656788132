import React, { useState, useEffect } from 'react';
import './ReviewList.css'; // CSSファイルをインポート

// 仮のデータを用意します（実際にはAPIからデータを取得することが一般的です）
const dummyReviews = [
    { id: 1, title: '不思議な森の謎解き冒険', rating: 4.5, summary: '家族で楽しめる素晴らしい体験でした！' },
    { id: 2, title: '怪盗からの脱出', rating: 4.0, summary: '難易度が高く、大人数での挑戦がおすすめです。' },
    { id: 3, title: '幽霊屋敷からの脱出', rating: 5.0, summary: 'とても怖かったけれど、スリルがあって面白かったです！' }
  
];

function ReviewList() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // ここでAPIからレビューデータを取得する処理を想定します。
    // fetchReviews() などの非同期関数を呼び出してデータを取得し、stateにセットします。
    setReviews(dummyReviews); // ここではダミーデータを使用しています。
  }, []);

  return (
    <div className="reviewList">
      <h1>みんなのレビュー</h1>
      <ul>
        {reviews.map(review => (
          <li key={review.id}>
            <h2>{review.title}</h2>
            <p>Rating: {review.rating} / 5.0</p>
            <p>{review.summary}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ReviewList;
