import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './CategoryPage.css';

// 仮のカテゴリデータ
const categoryReviews = {
  'family': [
    { id: 1, title: 'Mystery Mansion', rating: 5.0, summary: 'Perfect for family and friends!' },
    { id: 2, title: 'Pirate Ship Adventure', rating: 4.5, summary: 'Great fun for kids and adults alike!' }
  ],
  'thriller': [
    { id: 3, title: 'Haunted Hospital', rating: 4.8, summary: 'Scary and thrilling experience!' },
    { id: 4, title: 'Prison Break', rating: 4.9, summary: 'Intense and gripping!' }
  ]
};

function CategoryPage() {
  const { categoryName } = useParams();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // ここでカテゴリに基づいてレビューデータをフィルター処理します。
    setReviews(categoryReviews[categoryName] || []);
  }, [categoryName]);

  return (
    <div className="categoryPage">
      <h1>{categoryName.charAt(0).toUpperCase() + categoryName.slice(1)} Reviews</h1>
      {reviews.length > 0 ? (
        <ul>
          {reviews.map(review => (
            <li key={review.id}>
              <h2>{review.title}</h2>
              <p>Rating: {review.rating} / 5.0</p>
              <p>{review.summary}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No reviews available for this category.</p>
      )}
    </div>
  );
}

export default CategoryPage;
