import React from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';  
import HomePage from '../HomePage/HomePage';


function Logout() {
  return (
    <Authenticator>
        <HomePage />
    </Authenticator>
);
}

export default Logout;
