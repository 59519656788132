import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function EventDetail() {
  const { id } = useParams();  // useParams フックを使用して ID を取得
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(`https://4zpzmvcch9.execute-api.ap-northeast-1.amazonaws.com/prod/events/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setEvent(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id]);  // id の変更を依存配列に追加

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!event) return <div>No event found</div>;

  return (
    <div>
      <h2>{event.event_name}</h2>
      <p>{event.event_summary}</p>
      <div>{event.event_details}</div>
      <p>制作団体名: {event.organizer_name}</p>
      <p>料金: {event.price}</p>
      <p>成功率: {event.success_rate}%</p>
      <p>成功率(今年): {event.success_rate_recent}%</p>
      <p>参加人数: {event.total_participants}</p>
      <p>参加人数(今年): {event.participants_recent}</p>
    </div>
  );
  //TODO:
  //{event.event_url && <a href={event.event_url}>Event Page</a>}
  //{event.image_url && <img src={event.image_url} alt="Event" />}
}

export default EventDetail;
