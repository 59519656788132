import React from 'react';

function ImportExportButtons({ events, setEvents, allowedUserId, user }) {
  // エクスポート機能
  const handleExport = () => {
    const csvContent = events.map(event => 
      `${event.id},${event.event_name},${event.event_summary},${event.event_details},${event.price},${event.success_rate_recent},${event.total_participants},${event.participants_recent},${event.organizer_name},${event.creator_info},${event.registered_by_name},${event.event_url},${event.image_url},${event.version},${event.collaboration}`
    ).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'events.csv';
    link.click();
  };

  // インポート機能
  const handleImport = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      const importedEvents = text.split('\n').map(line => {
        const [id, event_name, event_summary, event_details, price, success_rate_recent, total_participants, participants_recent, organizer_name, creator_info, registered_by_name, event_url, image_url, version, collaboration] = line.split(',');
        return { id, event_name, event_summary, event_details, price, success_rate_recent, total_participants, participants_recent, organizer_name, creator_info, registered_by_name, event_url, image_url, version, collaboration };
      });
      setEvents(importedEvents);
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  return (
    <>
      {/* 特定のユーザーにのみインポート・エクスポートを表示 */}
      {user && user.username === allowedUserId && (
        <>
          <input
            type="file"
            accept=".csv"
            onChange={handleImport}
            className="importButton"
          />
          <button onClick={handleExport} className="exportButton">
            イベントをエクスポート
          </button>
        </>
      )}
    </>
  );
}

export default ImportExportButtons;
