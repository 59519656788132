import React from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';  // AuthenticatorとuseAuthenticatorをインポート
import '@aws-amplify/ui-react/styles.css';  // Ensure styles are imported
import './HomePage.css';

function HomePage() {
  const { user } = useAuthenticator((context) => [context.user]);  // 認証状態を取得

  return (
    <div className="homePage">
      <h1>謎解きログへようこそ！</h1>
      
      {/* ログイン中かどうかを判定し、ログイン時のみ挨拶を表示 */}
      {user ? (
        <p>こんにちは、{user.username}さん</p>  // ログイン時のメッセージ
      ) : (
        <p>謎解き公演のレビューサイトです。</p>  // ログインしていない場合のメッセージ
      )}

      <section className="featuredReviews">
        <h2>注目の公演</h2>
        <p>直近一ヶ月間で評価の高い公演です。</p>
      </section>

      <section className="latestNews">
        <h2>最新ニュース</h2>
        <p>coming soon</p>
      </section>
    </div>
  );
}

export default function App() {
  return (
    <Authenticator.Provider>
      <HomePage />
    </Authenticator.Provider>
  );
}
