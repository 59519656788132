import React from 'react';
import { Link } from 'react-router-dom';
import './CategoryList.css';

// カテゴリーのダミーデータ
const categories = [
  { name: 'SCRAP', path: 'scrap' },
  { name: 'Anoter Vision', path: 'anoter_vision' },
  { name: 'タンブルウィード', path: 'tanble' },
];

function CategoryList() {
  return (
    <div className="categoryList">
      <h1>謎作成団体一覧</h1>
      <ul>
        {categories.map((category) => (
          <li key={category.path}>
            <Link to={`/category/${category.path}`}>{category.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CategoryList;
