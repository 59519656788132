import React, { useState } from 'react';
import './AddEvent.css';

function AddEvent() {
  const [eventData, setEventData] = useState({
    eventName: '',
    eventSummary: '',
    eventDetails: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // APIへのPUTリクエストを行います
    try {
      const response = await fetch('https://4zpzmvcch9.execute-api.ap-northeast-1.amazonaws.com/prod/events', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(eventData)
      });
      if (!response.ok) {
        throw new Error('Something went wrong');
      }
      // フォーム送信後の処理（成功メッセージ表示など）
      alert('Event updated successfully!');
      // フォームの初期化
      setEventData({
        eventName: '',
        eventSummary: '',
        eventDetails: ''
      });
    } catch (error) {
      console.error('Failed to update event:', error);
      alert('Failed to update event.');
    }
  };

  return (
    <div>
      <h1>公演情報追加</h1>
      <form onSubmit={handleSubmit} className="addEventForm">
        <div>
          <label>
            公演名称:
            <input
              type="text"
              name="eventName"
              value={eventData.eventName}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            公演概要:
            <input
              type="text"
              name="eventSummary"
              value={eventData.eventSummary}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            公演詳細:
            <textarea
              name="eventDetails"
              value={eventData.eventDetails}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <button type="submit">Add Event</button>
      </form>
    </div>
  );
}

export default AddEvent;
