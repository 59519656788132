import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import ImportExportButtons from './ImportExportButtons';  // インポート・エクスポートボタンのコンポーネントをインポート
import './EventList.css';

function EventList() {
  const [events, setEvents] = useState([]);

  const allowedUserId = "87046a88-d001-70ce-4d48-8d817f1504ec";

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://4zpzmvcch9.execute-api.ap-northeast-1.amazonaws.com/prod/events');
        if (!response.ok) {
          throw new Error('Something went wrong');
        }
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <Authenticator>
      {({ user }) => (
        <div className="eventList">
          <h1>公演一覧</h1>

          <div className="buttonGroup">
            <Link to="/addevent" className="addEventButton">
              <button>公演を追加</button>
            </Link>
            {/* インポート・エクスポートボタンのコンポーネントを表示 */}
            <ImportExportButtons 
              events={events} 
              setEvents={setEvents} 
              allowedUserId={allowedUserId} 
              user={user}
            />
          </div>

          <ul>
            {events.length > 0 ? (
              events.map(event => (
                <li key={event.id}>
                  <Link to={`/event/${event.id}`}>
                    <h2>{event.event_name}</h2>
                    <p>団体: {event.organizer_name}</p>
                    <p>概要: {event.event_summary}</p>
                    <p>{event.event_details}</p>
                    <p>最近の成功率: {event.success_rate_recent}%</p>
                    <p>参加者: {event.total_participants}人</p>
                  </Link>
                </li>
              ))
            ) : (
              <p>No events found</p>
            )}
          </ul>
        </div>
      )}
    </Authenticator>
  );
}

export default EventList;
